<template>
<div class="flex flex-column flex-auto">
    <div class="p-5">
        <div class="grid">
            <!-- Admin options -->
            <div class="col-12">

                <Accordion v-if="companyName === 'Cyber Theft Watch'" :active-index="-1">
                    <AccordionTab v-if="clientParam !== ''">
                        <template #header>
                            <i class="pi pi-cog"></i>
                            <span>&nbsp;Admin Options</span>
                        </template>

                        <div class="grid">
                            <div class="col-6">
                                <Button class="w-full" label="Export All Assets" @click="exportAllAssets" />
                            </div>
                            <div class="col-6">
                                <Button class="w-full" label="Add New Asset" @click="addNewAsset" />
                            </div>
                        </div>
                    </AccordionTab>

                    <!-- Filters -->
                    <AccordionTab>
                        <template #header>
                            <i class="pi pi-cog"></i>
                            <span>&nbsp;Advanced Filters</span>
                        </template>

                        <p class="flex font-bold capitalize text-left">Asset Types</p>
                        <div class="grid">
                            <div class="col-3">
                                <Button :class="showMovie ? '' : 'p-button-outlined'" class="w-full" label="Movies" :icon="showMovie ? 'pi pi-eye' : 'pi pi-eye-slash'" @click="showMovie=!showMovie" />
                            </div>
                            <div class="col-3">
                                <Button :class="showBook ? '' : 'p-button-outlined'" class="w-full" label="Books" :icon="showBook ? 'pi pi-eye' : 'pi pi-eye-slash'" @click="showBook=!showBook" />
                            </div>
                            <div class="col-3">
                                <Button :class="showContentCreator ? '' : 'p-button-outlined'" class="w-full" label="Content Creator" :icon="showContentCreator ? 'pi pi-eye' : 'pi pi-eye-slash'" @click="showContentCreator=!showContentCreator" />
                            </div>
                            <div class="col-3">
                                <Button :class="showProducts ? '' : 'p-button-outlined'" class="w-full" label="Products" :icon="showProducts ? 'pi pi-eye' : 'pi pi-eye-slash'" @click="showProducts=!showProducts" />
                            </div>
                        </div>

                        <p class="flex font-bold capitalize text-left">Asset Status</p>
                        <div class="grid">
                            <div class="col-4">
                                <Button :class="showActive ? '' : 'p-button-outlined'" class="w-full" label="Active" :icon="showActive ? 'pi pi-eye' : 'pi pi-eye-slash'" @click="showActive=!showActive" />
                            </div>
                            <div class="col-4">
                                <Button :class="showInactive ? '' : 'p-button-outlined'" class="w-full" label="Inactive" :icon="showInactive ? 'pi pi-eye' : 'pi pi-eye-slash'" @click="showInactive=!showInactive" />
                            </div>
                            <div class="col-4">
                                <Button :class="showProspect ? '' : 'p-button-outlined'" class="w-full" label="Prospects" :icon="showProspect ? 'pi pi-eye' : 'pi pi-eye-slash'" @click="showProspect=!showProspect" />
                            </div>
                        </div>

                        <p class="flex font-bold capitalize text-left">Client Selection</p>
                        <MultiSelect v-model="selectedClients" :options="clients" optionLabel="name" placeholder="Select Clients" class="w-full" />

                        <p class="flex font-bold capitalize text-left">Subtype Selection</p>
                        <MultiSelect v-model="selectedSubtypes" :options="subtypes" optionLabel="name" placeholder="Select Subtype" class="w-full" />

                    </AccordionTab>
                </Accordion>
            </div>

            <!-- Active Assets -->
            <div v-if="showActive" class="col-12">
                <div class="surface-card shadow-2 border-round p-3 mb-3">
                    <div class="flex flex-row justify-content-between">
                        <p class="flex font-bold capitalize text-left text-xl m-2">Active Assets</p>
                    </div>
                </div>
            </div>

            <div v-for="asset in this.activeAssets" v-bind:key="asset.id" class="col-6 sm:col-3 lg:col-2">
                <router-link :to="'/asset/'+asset.id" style="text-decoration: none; color: inherit;" class="cursor-pointer">
                    <div v-ripple class="surface-card shadow-2 border-round p-3 hover:surface-200 transition-duration-150 transition-colors p-ripple">
                        <div class="flex align-items-center justify-content-center">
                            <div class="relative w-full">
                                <img class="border-round" style="object-fit: cover; width: 100%; aspect-ratio: 0.675;" v-bind:src="asset.image">
                                <font-awesome-icon v-if="asset.type === 'Movie'" icon="film" style="color: darkgrey" class="m-2 absolute bottom-0 right-0 text-6xl"/>
                                <font-awesome-icon v-if="asset.type === 'Book'" icon="book" style="color: darkgrey" class="m-3 absolute bottom-0 right-0 text-6xl"/>
                            </div>
                        </div>
                        <div class="flex align-items-center justify-content-center">
                            <p class="text-overflow-ellipsis" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                                {{ asset.name }}
                            </p>
                        </div>
                    </div>
                </router-link>
            </div>

            <!-- Prospect Assets -->
            <div v-if="showProspect && companyName === 'Cyber Theft Watch'" class="col-12">
                <div class="surface-card shadow-2 border-round p-3 mb-3">
                    <div class="flex flex-row justify-content-between">
                        <p class="flex font-bold capitalize text-left text-xl m-2">Prospect Assets</p>
                    </div>
                </div>
            </div>

            <div v-for="asset in this.prospectAssets" v-bind:key="asset.id" class="col-12 md:col-6 lg:col-2">
                <router-link :to="'/asset/'+asset.id" style="text-decoration: none; color: inherit;" class="cursor-pointer">
                    <div v-ripple v-bind:class="asset.active === true ? 'surface-card' : 'surface-300'" class="shadow-2 border-round p-3 hover:surface-200 transition-duration-150 transition-colors p-ripple">
                        <div class="flex align-items-center justify-content-center">
                            <div class="relative w-full">
                                <img class="border-round" :style="asset.active ? 'object-fit: cover; width: 100%; aspect-ratio: 0.675;' : 'filter: grayscale(100%); object-fit: cover; width: 100%; aspect-ratio: 0.675;'" v-bind:src="asset.image">

                                <font-awesome-icon v-if="asset.type.includes('Movie')" icon="film" style="color: darkgrey" class="m-3 absolute bottom-0 right-0 text-6xl lg:text-4xl xl:text-6xl"/>
                                <font-awesome-icon v-if="asset.type.includes('Book')" icon="book" style="color: darkgrey" class="m-3 absolute bottom-0 right-0 text-6xl lg:text-4xl xl:text-6xl"/>
                            </div>
                        </div>
                        <div class="flex align-items-center justify-content-center">
                            <p class="text-overflow-ellipsis" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                                {{ asset.name }}
                            </p>
                        </div>
                    </div>
                </router-link>
            </div>

            <!-- Inactive Assets -->
            <div v-if="showInactive" class="col-12">
                <div class="surface-card shadow-2 border-round p-3 mb-3">
                    <div class="flex flex-row justify-content-between">
                        <p class="flex font-bold capitalize text-left text-xl m-2">Inactive Assets</p>
                    </div>
                </div>
            </div>

            <div v-for="asset in this.inactiveAssets" v-bind:key="asset.id" class="col-12 md:col-6 lg:col-2">
                <router-link :to="'/asset/'+asset.id" style="text-decoration: none; color: inherit;" class="cursor-pointer">
                    <div v-ripple class="surface-card shadow-2 border-round p-3 hover:surface-200 transition-duration-150 transition-colors p-ripple">
                        <div class="flex align-items-center justify-content-center">
                            <div class="relative w-full">
                                <img class="border-round" style="filter: grayscale(100%); object-fit: cover; width: 100%; aspect-ratio: 0.675;" v-bind:src="asset.image">
                                <font-awesome-icon v-if="asset.type === 'Movie'" icon="film" style="color: darkgrey" class="m-3 absolute bottom-0 right-0 text-6xl lg:text-4xl xl:text-6xl"/>
                                <font-awesome-icon v-if="asset.type === 'Book'" icon="book" style="color: darkgrey" class="m-3 absolute bottom-0 right-0 text-6xl lg:text-4xl xl:text-6xl"/>
                            </div>
                        </div>
                        <div class="flex align-items-center justify-content-center">
                            <p class="text-overflow-ellipsis">
                                {{ asset.name }}
                            </p>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</div>
<AssetEditSimple v-if="clientParam !== null && companyName === 'Cyber Theft Watch'" ref="AssetEditSimple"/>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import AssetEditSimple from "@/components/modals/AssetEditSimple";

export default {
    name: "AssetViewer",

    components: {FontAwesomeIcon, AssetEditSimple},

    data: function() {
        return {
            companyName: null,
            activeAssets: [],
            inactiveAssets: [],
            prospectAssets: [],

            showMovie: true,
            showBook: true,
            showContentCreator: true,
            showProducts: true,

            showActive: true,
            showInactive: true,
            showProspect: false,

            selectedClients: [],
            clients: [],
            selectedClientNames: [],
            selectedSubtypes: [],
            subtypes: [],
            selectedSubtypeNames: [],

            clientParam: this.$route.params.clientName,
        }
    },

    computed: {
        showTypes() {
            return this.showMovie.toString() + this.showBook.toString() + this.showContentCreator.toString() + this.showProducts.toString();
        },
        showStatus() {
            return this.showActive.toString() + this.showInactive.toString() + this.showProspect.toString();
        }
    },

    mounted() {
        console.debug("Page Params", this.$route.params);
        this.getClients();
        this.getAssets();
        this.getSubtypes();

        // If current user is not ctw then enable all asset types, otherwise only enable movies
        if (this.companyName !== 'Cyber Theft Watch') {
            this.showMovie = true;
            this.showBook = true;
            this.showContentCreator = true;
            this.showProducts = true;
        } else {
            this.showMovie = true;
            this.showBook = false;
            this.showContentCreator = false;
            this.showProducts = false;
        }

        this.$emitter.on('assets-fetched', () => {
            this.getClients();
            this.getAssets();
            this.getSubtypes();
        });
    },

    methods: {
        getClients() {
            if (this.companyName !== 'Cyber Theft Watch') { return; }
            if (this.$store.getters.getCSRFToken === "") { setTimeout(this.getClients(), 250); }
            this.clients = [];
            this.selectedClients = [];

            this.$factory.client.getClientsAndLogos()
                .then(data => {
                    let lowerCaseClientData = [];
                    // Convert all keys to lowercase in array of objects
                    data.forEach((client) => {
                        let lowerCaseClient = {};
                        Object.keys(client).forEach((key) => {
                            lowerCaseClient[key.toLowerCase()] = client[key];
                        });
                        lowerCaseClientData.push(lowerCaseClient);
                    });

                    Object.values(lowerCaseClientData).forEach((client) => {
                        if (this.clients.some(e => e.name === client.name)) { return; }  // Check if client is already in list
                        this.clients.push(client)

                        // If client param is set, only show that client
                        if (this.clientParam !== undefined && this.clientParam === client.name) {
                            this.selectedClients.push(client)
                        } else if (this.clientParam === undefined || this.clientParam === '') {
                            this.selectedClients.push(client)
                        }
                    });
                })
                .catch(() => {
                    this.clients = [{'name': this.companyName}];
                    this.selectedClients = [{'name': this.companyName}];
                })
                .finally(() => {
                    return null;
                });
        },

        getAssets() {
            this.selectedSubtypeNames = Object.values(this.selectedSubtypes.map(subtype => subtype.name));
            this.selectedClientNames = Object.values(this.selectedClients.map(client => client.name));

            this.activeAssets = [];
            this.inactiveAssets = [];
            this.prospectAssets = [];

            this.filterActiveAssets();
            this.filterInactiveAssets();
            this.filterProspectAssets();
        },

        getSubtypes() {
            // Get active asset subtypes
            let arraySubtypes = [];
            this.$store.getters.getAllAssetData.forEach((asset) => {
                if (!arraySubtypes.includes(asset.subtype)) {
                    arraySubtypes.push(asset.subtype);
                }
            });

            // Get inactive asset subtypes
            this.$store.getters.getInactiveAssetData.forEach((asset) => {
                if (!arraySubtypes.includes(asset.subtype)) {
                    arraySubtypes.push(asset.subtype);
                }
            });

            // Push arraySubtypes to subtypes
            arraySubtypes.forEach((subtype) => {
                if (this.subtypes.some(e => e.name === subtype)) { return; }  // Check if subtype is already in list
                this.subtypes.push({'name': subtype});
                this.selectedSubtypes.push({'name': subtype});
            });
        },

        filterActiveAssets() {
            if (!this.showActive) { return }

            this.$store.getters.getActiveAssetData.forEach((asset) => {
                switch (asset.type) {
                    case 'Movie':
                        if (!this.showMovie) {
                            return;
                        }
                        break;
                    case 'Book':
                        if (!this.showBook) {
                            return;
                        }
                        break;
                    case 'Content Creator':
                        if (!this.showContentCreator) {
                            return;
                        }
                        break;
                    case 'Product':
                        if (!this.showProducts) {
                            return;
                        }
                        break;
                }

                if (this.companyName === 'Cyber Theft Watch' && !asset.type.includes('Prospect') && this.selectedClientNames.includes(asset.client) && this.selectedSubtypeNames.includes(asset.subtype)) {
                    this.activeAssets.push(asset);
                } else if (this.companyName !== 'Cyber Theft Watch' && !asset.type.includes('Prospect')) {
                    this.activeAssets.push(asset);
                }
            });
        },

        filterInactiveAssets() {
            if (!this.showInactive) { return }

            this.$store.getters.getInactiveAssetData.forEach((asset) => {
                switch (asset.type) {
                    case 'Movie':
                        if (!this.showMovie) {
                            return;
                        }
                        break;
                    case 'Book':
                        if (!this.showBook) {
                            return;
                        }
                        break;
                    case 'Content Creator':
                        if (!this.showContentCreator) {
                            return;
                        }
                        break;
                    case 'Product':
                        if (!this.showProducts) {
                            return;
                        }
                        break;
                }

                if (!asset.type.includes('Prospect') && this.selectedClientNames.includes(asset.client) && this.selectedSubtypeNames.includes(asset.subtype)) {
                    this.inactiveAssets.push(asset);
                } else if (this.companyName !== 'Cyber Theft Watch' && !asset.type.includes('Prospect')) {
                    this.inactiveAssets.push(asset);
                }
            });
        },

        filterProspectAssets() {
            if (!this.showProspect) { return }

            this.$store.getters.getAllAssetData.forEach((asset) => {
                switch (asset.type) {
                    case 'Movie':
                        if (!this.showMovie) {
                            return;
                        }
                        break;
                    case 'Book':
                        if (!this.showBook) {
                            return;
                        }
                        break;
                    case 'Content Creator':
                        if (!this.showContentCreator) {
                            return;
                        }
                        break;
                    case 'Product':
                        if (!this.showProducts) {
                            return;
                        }
                        break;
                }

                if (asset.type.includes('Prospect') && this.selectedClientNames.includes(asset.client) && this.selectedSubtypeNames.includes(asset.subtype)) {
                    this.prospectAssets.push(asset);
                }
            });
        },

        addNewAsset() {
            // Get client Id from client param
            let clientId = null;
            if (this.clientParam !== undefined && this.clientParam !== null && this.clientParam !== '') {
                this.clients.forEach((client) => {
                    if (client.name === this.clientParam) {
                        clientId = client.id;
                        return false;
                    }
                });
            }
            this.$refs.AssetEditSimple.show(-1, clientId);
        },

        exportAllAssets() {
            console.debug("Exporting all assets");
            // Get all assets for this client
            let clientAssets = '';
            Object.values(this.$store.getters.getAllAssetData).forEach((asset) => {
                if (this.selectedClientNames.includes(asset.client) && this.selectedSubtypeNames.includes(asset.subtype) && !asset.type.includes('Prospect') && asset.active === true) {
                    clientAssets += asset.id + ',';
                }
            });
            // Remove last comma
            clientAssets = clientAssets.slice(0, -1);
            this.$factory.export.exportAssetsToPDF(clientAssets)
                .then((data) => {
                    console.debug("Exported all assets", data);
                    this.$emitter.emit("good-toast", "Exported bulk PDF")
                })
                .catch((error) => {
                    console.error("Failed to export all assets", error);
                    this.$emitter.emit("bad-toast", "Export failed")
                });
        },
    },

    watch: {
        'showTypes': {
            handler() {
                this.getAssets();
            },
            deep: true,
            immediate: true,
        },
        'showStatus': {
            handler() {
                this.getAssets();
            },
            deep: true,
            immediate: true,
        },
        'selectedClients': {
            handler() {
                this.getAssets();
            },
            deep: true,
            immediate: true,
        },
        'selectedSubtypes': {
            handler() {
                this.getAssets();
            },
            deep: true,
            immediate: true,
        },
        '$store.state.userinfo.companyname': {
            handler() {
                this.companyName = this.$store.state.userinfo.companyname;
            },
            deep: true,
            immediate: true,
        },
        '$store.getters.getAllAssetData': {
            handler() {
                // Get everything again, race condition fix when logging in
                this.getClients();
                this.getSubtypes();
                this.getAssets();
            },
            deep: true,
            immediate: true,
        }
    },

}
</script>

<style scoped>

</style>
