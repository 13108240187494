<template>
<Dialog v-model:visible="visible" modal :header="'Edit ' + assetInfo.Name" :style="{ width: '60vw' }"
        maximizable>
    <div class="grid">
        <div class="col-12 lg:col-6">
            <!-- Asset Name -->
            <p class="mt-3 font-bold">Name</p>
            <InputText class="w-full" v-model="assetInfo.Name" />

            <!-- Active Status -->
            <p class="mt-3 font-bold">Active Status</p>
            <div class="flex align-items-center mr-4">
                <Checkbox v-model="options" class="" inputId="active-status" value="Active"/>
                <label class="ml-2" for="active-status">Active</label>
            </div>

            <!-- Asset Type -->
            <p class="mt-3 font-bold">Type</p>
            <InputText class="w-full" v-model="assetInfo.Type"/>

            <!-- Asset Subtype -->
            <p class="mt-3 font-bold">Subtype</p>
            <InputText class="w-full" v-model="assetInfo.Subtype"/>

            <!-- Client -->
            <p class="mt-3 font-bold">Client ID</p>
            <InputText class="w-full" v-model="assetInfo.Client"/>

            <!-- Asset Example url -->
            <p class="mt-3 font-bold">Example URL</p>
            <InputText class="w-full" v-model="assetInfo.ExampleURL"/>

            <!-- Poster -->
            <p class="mt-3 font-bold">Poster</p>
            <InputText class="w-full" v-model="assetInfo.Poster"/>

            <Accordion class="mt-3" active-index="-1">
                <AccordionTab header="Notes">
                    <!-- Notes -->
                    <p class="mt-3 font-bold">Notes</p>
                    <Textarea v-model="assetInfo.Notes" rows="3" cols="100" class="w-full" />
                </AccordionTab>
                <AccordionTab header="Terms">
                    <!-- Terms -->
                    <p class="mt-3 font-bold">Terms</p>
                    <DataTable :value="assetInfo.Terms">
                        <Column field="Name" header="Name" :sortable="true" />
                        <Column value="Delete" style="width: 10%; min-width: 8rem" bodyStyle="text-align:center">
                            <template #body="{ data }">
                                <div class="border-circle hover:text-900 hover:surface-100 cursor-pointer w-1rem h-1rem">
                                    <i class="pi pi-trash" @click="deleteTerm($event, data)"/>
                                </div>
                            </template>
                        </Column>
                    </DataTable>
                    <InputText class="w-full mt-2" v-model="newTerm"/>
                    <Button class="w-full mt-2" label="Add Term" @click="addTerm"/>
                </AccordionTab>

                <AccordionTab header="Advanced">
                    <!-- Priority -->
                    <p class="mt-3 font-bold">Priority</p>
                    <div class="flex align-items-center mr-4">
                        <Checkbox v-model="options" class="" inputId="priority-status" value="Priority"/>
                        <label class="ml-2" for="priority-status">Priority</label>
                    </div>

                    <!-- Priority Level -->
                    <p class="mt-3 font-bold">Priority Level</p>
                    <div class="flex align-items-center mr-4">
                        <InputNumber v-model="assetInfo.PriorityLevel" inputId="priority-level" mode="decimal" showButtons :min="1" :max="100" />
                        <label class="ml-2" for="priority-level">Priority Level</label>
                    </div>

                    <!-- Notice -->
                    <p class="mt-3 font-bold">Notice</p>
                    <Textarea v-model="assetInfo.Notice" rows="3" cols="100" class="w-full" />
                </AccordionTab>
            </Accordion>
        </div>

        <div class="col-12 lg:col-6">
            <div class="flex flex-column align-content-center justify-content-center mt-2 lg:mt-0">
                <p class="font-bold">Preview</p>
                <img v-if="assetInfo.Poster" class="border-round" v-bind:src="assetInfo.Poster" style="object-fit: cover; width: 50%; aspect-ratio: 0.675;">
                <img v-else class="border-round" src="https://placehold.co/675x1000?text=Insert+asset+poster" style="object-fit: cover; width: 50%; aspect-ratio: 0.675;">
            </div>

            <!-- TODO image vault -->
            <div v-if="assetId !== -1" class="">
                <p class="pt-2 font-bold">Image Vault</p>
                <div v-if="allImages.length > 0" class="grid">
                    <div v-for="img in allImages" v-bind:key="img.Id" class="col-3">
                        <div class="relative w-full">
                            <img style="width: 100%; object-fit: cover; aspect-ratio: 1" :src="img.image"/>
                            <i class="text-400 border-round hover:text-900 cursor-pointer pi pi-trash m-2 absolute top-0 right-0 text-4xl" @click="deleteImage($event, img)"/>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <p>No images in vault.</p>
                </div>
                <!-- TODO show all images -->
                <!-- TODO message if no images for this asset -->
            </div>

            <!-- Image Upload -->
            <div v-if="assetId !== -1" class="pt-2">
                <p class="pt-2 font-bold">Image Upload</p>
                <FileUpload name="caseImages[]" :multiple="true"
                            accept="image/*" :maxFileSize="10000000" custom-upload @uploader="uploadImages">
                    <template #empty>
                        <p>Drag and drop files to here to upload.</p>
                    </template>
                </FileUpload>
            </div>
        </div>
    </div>

    <template #footer>
        <Button v-if="!newAsset" class="w-full mt-2" label="Save" @click="save" :disabled="assetInfo.Name.length===0 || assetInfo.ExampleURL.length===0"/>
        <Button v-else class="w-full mt-2" label="Create" @click="create" :disabled="assetInfo.Name.length===0 || assetInfo.ExampleURL.length===0"/>
    </template>
</Dialog>
</template>

<script>
export default {
    name: "AssetEditSimple",

    data() {
        return {
            visible: false,
            assetId: -1,

            assetInfo: {},
            options: [],

            newAsset: false,
            newTerm: "",

            allImages: [],
        }
    },

    methods: {
        show(assetId, clientId) {
            // Reset
            this.newTerm = "";

            // Get asset data
            if (assetId > 0) {
                this.assetId = assetId;
                this.getAssetData();
                this.getImages();
            } else {
                this.newAsset = true;
                this.assetInfo = {
                    Id: -1,
                    Name: "",
                    Type: "Movie",
                    Subtype: "unknown",
                    Client: clientId,
                    ExampleURL: "",
                    Poster: "",
                    PriorityLevel: 1,
                    Notice: "",
                    ActiveStatus: true,
                    Priority: false,
                    Terms: [],
                    Notes: '',
                };
                this.visible = true;
            }
        },

        addTerm() {
            if (this.newTerm.length < 2) {
                return;
            }

            this.assetInfo.Terms.push({
                Name: this.newTerm,
            });

            this.newTerm = "";
        },

        deleteTerm(event, data) {
            this.$confirm.require({
                target: event.currentTarget,
                message: 'Are you sure you want to delete this entry?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.assetInfo.Terms.splice(this.assetInfo.Terms.indexOf(data), 1);
                },
            });
        },

        getAssetData() {
            this.$factory.asset.getAssetAllInfo(this.assetId)
                .then((response) => {
                    console.debug(response)
                    this.assetInfo = response

                    // Convert assetInfo options to options
                    this.options = []
                    if (response.ActiveStatus) {
                        this.options.push("Active")
                    }
                    if (response.Priority) {
                        this.options.push("Priority")
                    }

                    this.visible = true;
                }).catch(() => {
                    this.$emitter.emit("bad-toast", "Failed to get asset data.");
                })
        },

        save() {
            // Convert options to assetInfo options
            this.assetInfo.ActiveStatus = this.options.includes("Active")
            this.assetInfo.Priority = this.options.includes("Priority")

            if (this.assetInfo.Notice === null || this.assetInfo.Notice.length === 0) {
                this.assetInfo.Notice = null
            }

            if (this.assetInfo.Poster === null || this.assetInfo.Poster.length === 0) {
                this.assetInfo.Poster = null
            }

            console.debug(this.assetInfo)

            this.$factory.asset.updateAsset(this.assetInfo).then(() => {
                this.$emitter.emit("good-toast", "Asset updated successfully.");
                this.visible = false;
            }).catch(() => {
                this.$emitter.emit("bad-toast", "Failed to update asset.");
            })
        },

        create() {
            // Convert options to assetInfo options
            this.assetInfo.ActiveStatus = this.options.includes("Active")
            this.assetInfo.Priority = this.options.includes("Priority")

            if (this.assetInfo.Notice === null || this.assetInfo.Notice.length === 0) {
                this.assetInfo.Notice = null
            }

            if (this.assetInfo.Poster === null || this.assetInfo.Poster.length === 0) {
                this.assetInfo.Poster = null
            }

            this.$factory.asset.createAsset(this.assetInfo).then(() => {
                this.$emitter.emit("good-toast", "Asset created successfully.");
                this.$emitter.emit("asset-created")
                this.visible = false;
            }).catch(() => {
                this.$emitter.emit("bad-toast", "Failed to create asset.");
            })
        },

        getImages() {
            if (this.assetId === -1) {
                return;
            }
            this.$factory.asset.getAssetImages(this.assetId)
                .then(response => {
                    console.debug("Fetched images", response);
                    this.allImages = response.data;
                })
                .catch(error => {
                    console.error('get images', error)
                    this.$emitter.emit('bad-toast', 'Failed to fetch images from SmartVault');
                })
        },

        uploadImages(files) {
            console.debug("Uploading images", files);
            this.$factory.asset.uploadAssetImages(this.assetId, files)
                .then(response => {
                    console.debug("Uploaded images", response);
                    this.$emitter.emit('good-toast', 'Uploaded images');
                    this.getImages();
                })
                .catch(error => {
                    console.error('uploaded images', error)
                    this.$emitter.emit('bad-toast', 'Failed to upload images');
                })
        },

        deleteImage(event, image) {
            console.debug("Deleting image", image);
            console.debug(event)
            this.$factory.asset.deleteAssetImage(this.assetId, image.Id)
                .then(response => {
                    console.debug("Deleted image", response);
                    this.$emitter.emit('good-toast', 'Deleted image');
                    this.getImages();
                })
                .catch(error => {
                    console.error('delete image', error)
                    this.$emitter.emit('bad-toast', 'Failed to delete image');
                })
        },
    },
}
</script>

<style scoped>

</style>
